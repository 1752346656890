import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import styles from './styles';

const ButtonWithArrow = ({
  handleClick,
  title,
  arrowDirection,
  containerStyles,
  customizedArrow,
  customizedButton,
  disabled,
}) => (
  <div className="step-container " style={containerStyles}>
    <button
      className={`${customizedButton || 'step-next'}`}
      type="button"
      onClick={handleClick}
      disabled={disabled}
    >
      {arrowDirection.toLowerCase() === 'back' && (
        <span className={`${customizedArrow || 'icon'}`}>
          <Image draggable={false} src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-arrow-left_1.png?v=1714051208" height="20" width="20" alt="arrow_left"/>
        </span>
      )}
      <span className="text">{title}</span>
      {arrowDirection.toLowerCase() === 'next' && (
        <span className={`${customizedArrow || 'icon next'}`}>
          <Image draggable={false} src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon_arrow_5.png?v=1714047897" height="20" width="20" alt="arrow_right"/>
        </span>
      )}
    </button>

    <style jsx>{styles}</style>
  </div>
);

ButtonWithArrow.propTypes = {
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  arrowDirection: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  containerStyles: PropTypes.object,
  customizedArrow: PropTypes.string,
  customizedButton: PropTypes.string,
  disabled: PropTypes.bool,
};

ButtonWithArrow.defaultProps = {
  containerStyles: {},
  customizedArrow: undefined,
  customizedButton: undefined,
  disabled: false,
};

export default memo(ButtonWithArrow);
